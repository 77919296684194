const CONSTANTS = {
	DOC_PATH: '/choose',
	CREATE_PATH: '/create',
	MAX_DOCUMENT_DISPLAY_LENGTH: 30,
	MAX_TOPIC_LENGTH: 3600,
	FREE_WORD_LIMIT: 250,
	WORD_COUNT_MIN: 50,
	WORD_COUNT_MAX: 2500,
	TOPIC_GENERATION_BREAKPOINT: 700,
	TOKEN_MULTIPLIER: 1.7,
	INTRO_CONC_LENGTH: 200,
	MAX_BODY_PARAGRAPH_LENGTH: 500,
	ADD_MORE_MIN: 15,
	ADD_MORE_MAX: 250,
	REFERENCES_LOW_BREAKPOINT: 400,
	REFERENCES_HIGH_BREAKPOINT: 1000,
	ASK_AI_LIMIT: 200,
	EMPTY_DOCUMENT: '<p></p>',
}

const ASK_AI_TYPES = {
	fixGrammar: 'Fix Grammar',
	improve: 'Improve',
	simplify: 'Simplify',
	shorten: 'Shorten',
}
const ASK_AI_DESCRIPTIONS = {
	'Fix Grammar': 'Fix any mistakes in your grammar or punctuation.',
	Improve: 'Improve the writing style, word choice, and/or sentence structure.',
	Simplify: 'Reduce the reading level so it is easier to understand.',
	Shorten: 'Reduce the word count while retaining the core meaning.',
}

const TEMP_PRESETS = {
	deterministic: 0,
	normal: 0.3,
	high: 0.6,
	random: 1,
}

const DOC_TEMPLATES = {
	Blank: 'Blank',
	Outline: 'Outline',
	Writer: 'Writer',
}

const READING_LEVEL_OPTIONS = ['Elementary', 'Middle School', 'High School', 'College', 'Graduate School']
const TYPE_OPTIONS = ['Classic', 'Compare/Contrast', 'Argumentative', 'Persuasive', 'Critique', 'Memoir']
const TONE_OPTIONS = ['Academic', 'Sarcastic', 'Informal', 'Assertive', 'Friendly', 'Humorous']
const HUMANIZER_OPTIONS = ['NONE', 'LOW', 'MEDIUM', 'HIGH']
const NULL_USER = { planId: null }

module.exports = {
	CONSTANTS,
	TEMP_PRESETS,
	READING_LEVEL_OPTIONS,
	TYPE_OPTIONS,
	TONE_OPTIONS,
	HUMANIZER_OPTIONS,
	NULL_USER,
	ASK_AI_TYPES,
	ASK_AI_DESCRIPTIONS,
	DOC_TEMPLATES,
}
