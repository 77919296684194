import Loader from '../Loader'
import Modal from './Modal'
import './_Detection.scss'

const DetectionModal = ({
	open,
	closeModal,
	result,
	loading,
}: {
	open: boolean
	closeModal: () => void
	result: number | null
	loading: boolean
}) => {
	if (!loading && result === null) {
		return null
	}
	const score = (result ?? 0) * 100

	return (
		<Modal open={open} closeModal={closeModal} portalClassName="aiDetection">
			{loading || result === null ? (
				<div className="loading">
					<Loader />
				</div>
			) : (
				<div>
					<h5 className="header-md">Detection Results</h5>
					<div className="detectionResults">
						<div className="resultContainer">
							<div className="result" style={{ width: score + '%' }} />
						</div>
						<div className="labels">
							<p>Human</p>
							<p>AI</p>
						</div>
						<p className="scoreText">
							This document contains <b>{Math.floor(score)}%</b> AI generated content.
						</p>
						<p className="small-text">
							<br />
							Detection is provided by{' '}
							<a target="_blank" href="https://www.the-ghost-ai.com/" rel="noreferrer">
								Ghost AI
							</a>
							. Any score under 50% is considered undetectable. If AI content is detected, it is recommended to
							re-generate the content or increase the humanizer intensity.
						</p>
					</div>
				</div>
			)}
		</Modal>
	)
}

export default DetectionModal
