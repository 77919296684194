import { useEffect, useState, useMemo } from 'react'
import './_Navbar.scss'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import Button from '../Buttons'
import { CONSTANTS } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { selectShowNav, selectUser, setShowNav } from '../../redux/systemSlice'
import { selectDocumentState } from '../../redux/docSlice'
import { useAuth } from '../../contexts/Auth'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckIcon from '@mui/icons-material/Check'
import { useIsAuthenticated } from '../../hooks/authenticated'
import { htmlToString, useIsInApp } from '../../helpers/utility'
import { DocumentState } from '../../redux/types'
import Detector from './Detector'
import { useDocId } from '../../hooks/docID'
import useEnterApp from '../../hooks/enterApp'
import AccountModal from '../Modals/Account'
import LoginModal from '../Modals/Login'
import Upsell from '../Modals/Upsell'
import Subscriptions from '../Modals/Subscriptions'
import WordCount from './WordCount'

const baseClassNames =
	'flex justify-between items-center mx-auto md:h-auto transition-all duration-500 px-4 py-2 md:py-3 md:px-auto'

function Navbar(props: RouteComponentProps) {
	const user = useSelector(selectUser)
	const [createUserLoading, setCreateUserLoading] = useState(false)
	const [copied, setCopied] = useState(false)
	const [accountModalOpen, setAccountModalOpen] = useState(false)
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const [upsellModalOpen, setUpsellModalOpen] = useState(false)
	const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)

	const logo = <span className="text-4xl md:text-5xl leading-0 w-0 relative right-1">○</span>
	const dispatch = useDispatch()
	const showNavRedux = useSelector(selectShowNav)
	const docID = useDocId()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const pathname = window.location.pathname
	const isInApp = useIsInApp()
	const { currentUser } = useAuth() as any
	const isAuthenticated = useIsAuthenticated()
	const { enterApp } = useEnterApp({ history: props.history, setLoading: setCreateUserLoading })
	const [isPremiumPath, setIsPremiumPath] = useState(false)

	//Control showing the nav bar for user mouse move and scroll
	useEffect(() => {
		const onMouseMove = () => {
			dispatch(setShowNav({ value: true }))
		}
		const onScroll = () => {
			const documentPathname = document.location.pathname
			if ([CONSTANTS.CREATE_PATH, CONSTANTS.DOC_PATH].includes(documentPathname)) {
				return
			}
			dispatch(setShowNav({ value: true }))
		}

		window.addEventListener('scroll', onScroll)
		window.addEventListener('mousemove', onMouseMove)
		return () => {
			window.removeEventListener('mousemove', onMouseMove)
			window.removeEventListener('scroll', onScroll)
		}
	})

	//Switch back from check to copy icon after timeout
	useEffect(() => {
		if (copied) {
			const timeoutId = setTimeout(() => {
				setCopied(false)
			}, 2000)
			return () => clearTimeout(timeoutId)
		}
	}, [copied])

	const handleCopyClick = () => {
		if (documentState.isGenerating || documentState.isTypingAddMore || documentState.isTypingEssay) return
		setCopied(true)
	}
	const nameAndLogo = (
		<Link to={'/'} className="flex items-center md:gap-2 gap-0">
			<span className="logo">{logo} </span>
			<span className="lowercase text-primary text-lg md:text-[26px] font-semibold">
				<span>
					The Good <span className="text-gray-500 fancy font-normal">AI</span>
				</span>
			</span>
		</Link>
	)

	const basicNavbar = {
		desktop: (
			<>
				<div
					id="desktopNavbar"
					className={`${baseClassNames} px-4 lg:px-56 max-w-[1800px] basic ` + (showNavRedux ? 'visible' : '')}
				>
					{nameAndLogo}
					<span onClick={() => enterApp()}>
						<Button type="tertiary">
							<span>{createUserLoading ? 'Loading...' : isAuthenticated ? 'Launch App' : 'Use for Free'}</span>
						</Button>
					</span>
				</div>
			</>
		),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} basic ` + (showNavRedux ? 'visible' : '')}>
				{nameAndLogo}
				<span onClick={() => enterApp()}>
					<Button type="tertiary">
						<span>{createUserLoading ? 'Loading...' : isAuthenticated ? 'Launch App' : 'Use for Free'}</span>
					</Button>
				</span>
			</div>
		),
	}

	function docNavbarCTAFunc() {
		if (currentUser) {
			setAccountModalOpen(true)
		} else {
			setLoginModalOpen(true)
		}
	}

	const docNavbar = {
		desktop: (
			<div id="desktopNavbar" className={`${baseClassNames} doc ` + (showNavRedux ? 'visible' : '')}>
				<Link to={'/'}>
					<span className="logo">{logo} </span>
				</Link>
				<div className="flex items-center gap-2">
					<Button type="tertiary" onClick={docNavbarCTAFunc}>
						<span>{currentUser ? 'ACCOUNT' : 'LOG IN'}</span>
					</Button>
					{/* {!currentUser && (
						<Button type="tertiary" onClick={docNavbarCTAFunc}>
							<span>SIGN UP</span>
						</Button>
					)} */}
				</div>
			</div>
		),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} doc ` + (showNavRedux ? 'visible' : '')}>
				<div className="flex justify-between w-full items-center">
					<Link to={'/'}>
						<span className="logo">{logo}</span>
					</Link>
					<div className="flex items-center gap-2">
						<Button type="tertiary" onClick={docNavbarCTAFunc}>
							<span>{currentUser ? 'ACCOUNT' : 'LOG IN'}</span>
						</Button>
						{/* {!currentUser && (
							<Button type="tertiary" className="hover:bg-secondary" onClick={docNavbarCTAFunc}>
								<span>SIGN UP</span>
							</Button>
						)} */}
					</div>
				</div>
			</div>
		),
	}

	const newDocNavbar = {
		desktop: (
			<div id="desktopNavbar" className={`${baseClassNames} doc ` + (showNavRedux ? 'visible' : '')}>
				<Link to={'/'}>
					<span className="logo">{logo} </span>
				</Link>
			</div>
		),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} doc ` + (showNavRedux ? 'visible' : '')}>
				<div className="flex justify-between w-full items-center">
					<Link to={'/'}>
						<span className="logo">{logo}</span>
					</Link>
				</div>
			</div>
		),
	}

	const createNavbar = {
		desktop: (
			<div id="desktopNavbar" className={`${baseClassNames} create ` + (showNavRedux ? 'visible' : '')}>
				<Link to={CONSTANTS.DOC_PATH} className="h-8">
					<span className="back relative bottom-4">⤑</span>
				</Link>
				{documentState && documentState.setup && (
					<>
						<div className="desktopNavRight flex items-center">
							<span className="saveAuto">Saves automatically</span>
							{!documentState.isGenerating && <WordCount />}
							<CopyToClipboard text={htmlToString(documentState.content ?? '')} onCopy={handleCopyClick}>
								<Button
									className="copyButton"
									type="primary"
									style={{ width: '30px' }}
									disabled={
										documentState.isGenerating ||
										documentState.isTypingAddMore ||
										documentState.isTypingEssay ||
										documentState.content === CONSTANTS.EMPTY_DOCUMENT ||
										documentState.content === ''
									}
								>
									<span>
										<CheckIcon className={`icon ${copied ? ' checkVisible' : ' checkHidden'}`} />
										<ContentCopyIcon className={`icon ${copied ? ' copyHidden' : ' copyVisible'}`} />
									</span>
								</Button>
							</CopyToClipboard>
							{(user.planIdHumanizer || user.planId) && <Detector />}
							{!user.planId && (
								<Button
									type="tertiary"
									onClick={() => {
										setSubscriptionModalOpen(true)
									}}
								>
									Upgrade
								</Button>
							)}
						</div>
					</>
				)}
			</div>
		),
		mobile: (
			<div id="mobileNavbar" className={`${baseClassNames} create ` + (showNavRedux ? 'visible' : '')}>
				<div className="flex justify-between w-full items-center">
					<Link to={CONSTANTS.DOC_PATH} className="h-12 relative bottom-3">
						<span className="back">⤑</span>
					</Link>
					{(user.planIdHumanizer || user.planId) && <Detector />}
					{!user.planId && documentState && documentState.setup && (
						<Button
							type="tertiary"
							onClick={() => {
								setSubscriptionModalOpen(true)
							}}
						>
							Upgrade
						</Button>
					)}
				</div>
			</div>
		),
	}
	const navigation = [
		{ path: '/examples', nav: basicNavbar },
		{ path: '/examples/', nav: basicNavbar },
		{ path: CONSTANTS.DOC_PATH, nav: docNavbar },
		{ path: CONSTANTS.CREATE_PATH, nav: createNavbar },
		{ path: '/new', nav: newDocNavbar },
		{ path: '/', nav: basicNavbar },
	]

	let navBar = basicNavbar

	for (let i = 0; i < navigation.length; i++) {
		if (pathname.includes(navigation[i].path)) {
			navBar = navigation[i].nav
			break
		}
	}

	return (
		<>
			<Subscriptions
				open={subscriptionModalOpen}
				closeModal={() => {
					setSubscriptionModalOpen(false)
				}}
				setLoginModal={(open: boolean) => {
					setLoginModalOpen(open)
					if (open) {
						setIsPremiumPath(true)
					}
				}}
				openUpsellModal={() => setUpsellModalOpen(true)}
			/>
			<AccountModal isOpen={accountModalOpen} closeModal={() => setAccountModalOpen(false)} />
			<LoginModal
				open={loginModalOpen}
				setModalOpen={setLoginModalOpen}
				premiumPath={isPremiumPath}
				openUpsellModal={() => setUpsellModalOpen(true)}
			/>
			<Upsell
				isOpen={upsellModalOpen}
				closeModal={() => setUpsellModalOpen(false)}
				openLoginModal={() => setLoginModalOpen(true)}
			/>
			<div className="bg-white w-full fixed top-0 z-50">
				<div className="block md:hidden">{navBar.mobile}</div>
				<div className="hidden md:block">{navBar.desktop}</div>
			</div>
		</>
	)
}

export default withRouter(Navbar)
