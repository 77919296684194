import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '../contexts/Auth'
import { useCookieUser } from './cookieUser'
import { DocumentState, UserWriterSelections } from '../redux/types'
import {
	selectDocumentState,
	setContent,
	setIsGenerating,
	setIsGeneratingSubtopics,
	setIsHumanizing,
	setIsOnSubtopicsStep,
	setIsTypingEssay,
	setNeedsHumanizing,
	setTitle,
	setUserWriterSelections,
	setSetup,
	setSetupChanged,
} from '../redux/docSlice'
import { CONSTANTS, DOC_TEMPLATES, HUMANIZER_OPTIONS } from '../constants'
import { useEffect } from 'react'
import { selectUser, setShowHelpBanner } from '../redux/systemSlice'
import * as Sentry from '@sentry/react'

const useWriteEssay = ({
	docID,
	type,
	topicValue,
	setTopicValue,
	setLocalUserWriterSelections,
	openSubscriptionModal,
	setErrorModalOpen,
}: {
	type: keyof typeof DOC_TEMPLATES | undefined
	docID: string
	topicValue: string
	setTopicValue: (value: string) => void
	setLocalUserWriterSelections: (value: UserWriterSelections) => void
	openSubscriptionModal?: () => void
	setErrorModalOpen: (value: boolean) => void
}) => {
	const { userIDCookie } = useCookieUser()
	const { currentUser } = useAuth() as any
	const dispatch = useDispatch()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const user = useSelector(selectUser)

	useEffect(() => {
		setTopicValue(documentState?.title ?? '')
	}, [documentState?.title])

	async function makeHumanizerRequest(essayContent: string) {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				text: essayContent,
				humanizerIntensity: documentState.userWriterSelections?.humanizerIntensity,
				token: currentUser ? await currentUser.getIdToken() : undefined,
				userID: user.id ?? userIDCookie,
			}),
		}

		try {
			// Await the fetch call and its conversion to JSON
			const response = await fetch(process.env.REACT_APP_API_ROOT + '/essay/humanize/', requestOptions)
			const result = await response.json()
			return result
		} catch (e) {
			Sentry.captureException(e)
			setErrorModalOpen(true)
			// Ensure that in case of error, the function returns a fallback or error indicator
			return '' // Consider returning null or a specific error object depending on your handling logic
		}
	}

	async function humanizeEssay(essayContent: string) {
		dispatch(setIsHumanizing({ docID: docID, isHumanizing: true }))

		let chunks = []

		if (essayContent.length > 1250 * 5) {
			// split the essay into two chunks based on newlines
			const splitContent = essayContent.split('\n')
			const mid = Math.floor(splitContent.length / 2)
			const firstHalf = splitContent.slice(0, mid).join('\n')
			const secondHalf = splitContent.slice(mid).join('\n')
			chunks = [firstHalf, secondHalf]
		} else {
			chunks = [essayContent]
		}

		let humanizedContent = ''

		for (let i = 0; i < chunks.length; i++) {
			const result = await makeHumanizerRequest(chunks[i])
			humanizedContent += result
		}

		dispatch(setContent({ docID: docID, content: humanizedContent }))
		dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
		dispatch(setIsHumanizing({ docID: docID, isHumanizing: false }))
		dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: false }))
		dispatch(setNeedsHumanizing({ docID: docID, needsHumanizing: false }))
	}

	async function writeOutline() {
		dispatch(setIsGenerating({ docID: docID, isGenerating: true }))
		const requestBody = JSON.stringify({
			prompt: topicValue,
			userId: user.id ?? userIDCookie,
			docId: docID,
			userWriterSelections: documentState.userWriterSelections,
		})

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: requestBody,
		}

		const url = `${process['env']['REACT_APP_API_ROOT']}/essay/write-outline/`
		fetch(url, requestOptions)
			.then((res) => {
				dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: true }))
				dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
				return res.json()
			})
			.then(async (result: { essay: string }) => {
				dispatch(setContent({ docID: docID, content: result.essay.trim() }))
				dispatch(setShowHelpBanner({ value: true }))
				dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: false }))
				if (documentState.userWriterSelections?.humanizerIntensity !== HUMANIZER_OPTIONS[0] && !!user.planIdHumanizer) {
					await humanizeEssay(result.essay.trim())
				}
				setTimeout(() => {
					dispatch(setShowHelpBanner({ value: false }))
				}, 4000)
			})
			.catch((e) => {
				Sentry.captureException(e)
				setErrorModalOpen(true)
				dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
				dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: false }))
				dispatch(setShowHelpBanner({ value: true }))
				dispatch(setContent({ docID: docID, content: '' }))
			})
	}

	async function getSectionContent(
		topic: string,
		sectionType: 'introduction' | 'body' | 'conclusion',
		essayContent: string
	) {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				prompt: topicValue,
				userId: user.id ?? userIDCookie,
				docId: docID,
				userWriterSelections: documentState.userWriterSelections,
				sectionType: sectionType,
				topic: topic,
				essay: essayContent,
			}),
		}

		const url = `${process['env']['REACT_APP_API_ROOT']}/essay/write-section/`

		try {
			const response = await fetch(url, requestOptions)
			const result = await response.json()
			return result.essay.trim()
		} catch (e) {
			Sentry.captureException(e)
			throw new Error('Failed to fetch section content')
		}
	}

	async function writeLongEssay() {
		dispatch(setIsGenerating({ docID: docID, isGenerating: true }))

		let content = ''
		try {
			for (let i = 0; i < documentState.userWriterSelections.subTopics.length; i++) {
				const sectionType =
					i === 0
						? 'introduction'
						: i === documentState.userWriterSelections.subTopics.length - 1
						? 'conclusion'
						: 'body'
				const sectionContent = await getSectionContent(
					documentState.userWriterSelections.subTopics[i],
					sectionType,
					content
				)
				content += sectionContent.trim()
			}
			dispatch(setContent({ docID: docID, content: content }))

			dispatch(setShowHelpBanner({ value: true }))
			dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: false }))
			dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
			if (documentState.userWriterSelections?.humanizerIntensity !== HUMANIZER_OPTIONS[0] && !!user.planIdHumanizer) {
				await humanizeEssay(content.trim())
			}
			setTimeout(() => {
				dispatch(setShowHelpBanner({ value: false }))
			}, 4000)
		} catch (e) {
			Sentry.captureException(e)
			setErrorModalOpen(true)
			dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
			dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: false }))
			dispatch(setShowHelpBanner({ value: true }))
			dispatch(setContent({ docID: docID, content: '' }))
		}
	}

	async function writeShortEssay() {
		dispatch(setIsGenerating({ docID: docID, isGenerating: true }))
		const requestBody = JSON.stringify({
			prompt: topicValue,
			userId: user.id ?? userIDCookie,
			docId: docID,
			userWriterSelections: documentState.userWriterSelections,
		})

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: requestBody,
		}

		const url = `${process['env']['REACT_APP_API_ROOT']}/essay/write-short-essay/`
		fetch(url, requestOptions)
			.then((res) => {
				dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: true }))
				dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
				return res.json()
			})
			.then(async (result: { essay: string }) => {
				dispatch(setContent({ docID: docID, content: result.essay.trim() }))
				dispatch(setShowHelpBanner({ value: true }))
				dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: false }))
				if (documentState.userWriterSelections?.humanizerIntensity !== HUMANIZER_OPTIONS[0] && !!user.planIdHumanizer) {
					await humanizeEssay(result.essay.trim())
				}
				setTimeout(() => {
					dispatch(setShowHelpBanner({ value: false }))
				}, 4000)
			})
			.catch((e) => {
				Sentry.captureException(e)
				setErrorModalOpen(true)
				dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
				dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: false }))
				dispatch(setShowHelpBanner({ value: true }))
				dispatch(setContent({ docID: docID, content: '' }))
			})
	}

	async function generateContent() {
		if (
			!user.planId &&
			(CONSTANTS.FREE_WORD_LIMIT < documentState.userWriterSelections?.wordCount ||
				documentState.userWriterSelections?.includeRefs)
		) {
			openSubscriptionModal?.()
			return
		}
		dispatch(setSetup({ docID: docID, setup: true }))
		dispatch(setSetupChanged({ docID: docID, setupChanged: true }))
		dispatch(setTitle({ docID: docID, title: topicValue }))

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				topic: topicValue,
				id: docID,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/document/update/', requestOptions)
			.then((res) => res.json())
			.then((success: boolean) => {
				if (success) {
					setLocalUserWriterSelections(documentState.userWriterSelections)

					if (type === DOC_TEMPLATES.Outline) {
						writeOutline()
					} else if (documentState.userWriterSelections?.wordCount >= CONSTANTS.TOPIC_GENERATION_BREAKPOINT) {
						writeLongEssay()
					} else {
						writeShortEssay()
					}
				} else {
					dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
				}
			})
			.catch((e) => {
				Sentry.captureException(e)
				dispatch(setIsGenerating({ docID: docID, isGenerating: false }))
			})
	}

	const getSubtopics = async () => {
		dispatch(setIsGeneratingSubtopics({ docID: docID, isGeneratingSubtopics: true }))
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				wordCount: documentState.userWriterSelections.wordCount,
				prompt: topicValue,
				type: documentState.userWriterSelections.type,
				userId: user.id ?? userIDCookie,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/subtopics/', requestOptions)
			.then((res) => res.json())
			.then((response: { outline: string[] }) => {
				dispatch(
					setUserWriterSelections({
						docID: docID,
						userWriterSelections: {
							...documentState.userWriterSelections,
							subTopics: response.outline,
						},
					})
				)
				dispatch(
					setIsOnSubtopicsStep({
						docID: docID,
						isOnSubtopicsStep: true,
					})
				)
				dispatch(setIsGeneratingSubtopics({ docID: docID, isGeneratingSubtopics: false }))
			})
			.catch((e) => {
				Sentry.captureException(e)
				dispatch(setIsGeneratingSubtopics({ docID: docID, isGeneratingSubtopics: false }))
			})
	}

	return { generateEssay: generateContent, getSubtopics }
}

export default useWriteEssay
