import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useAuth } from '../../contexts/Auth'
import { selectUser } from '../../redux/systemSlice'
import Button from '../Buttons'
import Loader from '../Loader'
import * as Sentry from '@sentry/react'
import './_Modal.scss'
import { isValidEmail } from '../../helpers/utility'
import './_Reauth.scss'
import Modal from './Modal'

const errorToText = {
	'auth/invalid-email': 'Please enter a valid email.',
	'auth/wrong-password': 'Incorrect password',
	'auth/email-already-in-use': 'This email is already in use by another account',
	'invalid-password': 'Please enter a valid password',
	unknown: 'Unknown error, please try again.',
}

function ReauthModal({
	isOpen,
	closeModal,
	newEmail,
	setError,
}: {
	isOpen: boolean
	closeModal: () => void
	newEmail: string
	setError: (error: any) => void
}) {
	const { currentUser, updateUserEmail, reauthenticateUserWithCredential } = useAuth() as any
	const user = useSelector(selectUser)
	const [emailInput, setEmailInput] = useState(currentUser?.email ?? '')
	const [passwordInput, setPasswordInput] = useState<string | undefined>('')
	const [loading, setLoading] = useState(false)
	const [reauthError, setReauthError] = useState<
		| undefined
		| 'auth/invalid-email'
		| 'auth/email-already-in-use'
		| 'auth/wrong-password'
		| 'invalid-password'
		| 'unknown'
	>(undefined)

	async function submitForm() {
		//Check valid email and password
		if (!passwordInput || passwordInput.length <= 6) {
			setReauthError('invalid-password')
			return
		}

		if (!emailInput || !isValidEmail(emailInput)) {
			setReauthError('auth/invalid-email')
			return
		}

		setLoading(true)

		const formattedEmailInput = emailInput.toLowerCase()
		//reauth user and update email on firebase
		try {
			await reauthenticateUserWithCredential(formattedEmailInput, passwordInput)
			await updateUserEmail(newEmail)
		} catch (e: any) {
			setLoading(false)

			//throw error back to account modal so user can change email and retry
			if (e.code === 'auth/email-already-in-use') {
				setError(e.code)
				closeModal()
			} else {
				setReauthError(e.code)
			}
			return
		}

		//update db user
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({ user_id: user.id, email: newEmail }),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/user/update/', requestOptions)
			.then((res) => res.json())
			.then((result: any) => {
				setLoading(false)
				closeModal()
			})
			.catch((err) => Sentry.captureException(err))
	}

	if (loading) {
		return (
			<div className="loading">
				<Loader />
			</div>
		)
	}

	return (
		<Modal closeModal={closeModal} open={isOpen} portalClassName="reauth">
			<div>
				<h5 className="fancy header-md">Sign in</h5>
				<p>Please sign in with your old email to finalize your email change.</p>
				<input
					placeholder="Enter your current email address..."
					onChange={(e) => {
						setEmailInput(e.target.value)
						setReauthError(undefined)
					}}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							submitForm()
						}
					}}
					value={emailInput}
					type="email"
					id="enter-email-address"
				/>
				<input
					type="password"
					id="the-password"
					placeholder={'Enter your password...'}
					value={passwordInput}
					onChange={(e) => {
						setPasswordInput(e.target.value)
						setReauthError(undefined)
					}}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							submitForm()
						}
					}}
				/>
				<Button type="tertiary" onClick={submitForm}>
					<span>Sign in</span>
				</Button>
				{reauthError && <div className="error">{errorToText[reauthError]}</div>}
			</div>
		</Modal>
	)
}

export default ReauthModal
