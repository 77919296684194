import { useState } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch } from 'react-redux'
import { useAuth } from '../../contexts/Auth'
import { setUser, setUserLoading } from '../../redux/systemSlice'
import Button from '../Buttons'
import Loader from '../Loader'
import './_Modal.scss'
import * as Sentry from '@sentry/react'
import { isValidEmail } from '../../helpers/utility'
import './_Login.scss'
import Modal from './Modal'
import { NULL_USER } from '../../constants'

const errorToText = {
	'invalid-email': 'Please enter a valid email.',
	'invalid-password': 'Password must be at least 6 characters.',
	'wrong-password': 'Invalid password',
	unknown: 'Unknown error, please try again.',
}

function LoginModal({
	open,
	setModalOpen,
	email,
	premiumPath,
	openUpsellModal,
}: {
	open: boolean
	setModalOpen: (open: boolean) => void
	email?: string
	premiumPath?: boolean
	openUpsellModal: () => void
}) {
	const [emailInput, setEmailInput] = useState<string | undefined>(email ?? '')
	const [passwordInput, setPasswordInput] = useState<string | undefined>('')
	const [loading, setLoading] = useState(false)
	const [hasLogin, setHasLogin] = useState(false)
	const [forgotPassword, setForgotPassword] = useState(false)
	const [error, setError] = useState<undefined | 'invalid-email' | 'invalid-password' | 'wrong-password' | 'unknown'>(
		undefined
	)
	const [emailSubmitted, setEmailSubmitted] = useState<string | undefined>(undefined)
	const { signup, signin, resetPassword, setCurrentUser } = useAuth() as any
	const [cookies, setCookie] = useCookies(['docs'])
	const dispatch = useDispatch()

	async function createUser(uid: string, email: string) {
		if (!uid || !email) throw Error('Called /user/create/ with missing uid and/or email')
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				email,
				uid,
				existingDocs: cookies?.docs?.length > 0 ? cookies.docs : [],
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/user/create/', requestOptions)
			.then((res) => res.json())
			.then((result) => {
				dispatch(setUser(result))
				setCookie('docs', JSON.stringify([]))
			})
			.catch((err) => Sentry.captureException(err))
	}

	const validPassword = passwordInput && passwordInput.length > 6

	async function submitForm() {
		const formattedEmailInput = emailInput?.toLowerCase() ?? ''
		Sentry.setUser({ email: formattedEmailInput })

		if (!emailSubmitted) {
			if (emailInput && isValidEmail(emailInput)) {
				setLoading(true)
				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						email: formattedEmailInput,
						anonymous: false,
					}),
				}
				fetch(process['env']['REACT_APP_API_ROOT'] + '/user/retrieve/', requestOptions)
					.then((res) => res.json())
					.then((result: any) => {
						setLoading(false)
						setEmailSubmitted(formattedEmailInput)
						setHasLogin(result && result.uid && result.uid.length > 5)
						dispatch(setUser(result))
					})
					.catch((err) => Sentry.captureException(err))
			} else {
				setError('invalid-email')
			}
		} else {
			if (hasLogin) {
				try {
					const result = await signin(formattedEmailInput, passwordInput)
					setCurrentUser(result.user)
					setModalOpen(false)
					if (premiumPath) {
						openUpsellModal()
						setModalOpen(false)
					}
				} catch {
					setError('wrong-password')
				}
			} else if (passwordInput && validPassword) {
				try {
					setLoading(true)
					const result = await signup(formattedEmailInput, passwordInput)
					setCookie('docs', [])
					dispatch(setUserLoading({ value: true }))
					await createUser(result.user.uid, formattedEmailInput)
					setCurrentUser(result.user)
					dispatch(setUserLoading({ value: false }))
					setModalOpen(false)

					if (premiumPath) {
						openUpsellModal()
						setModalOpen(false)
					}
				} catch (error) {
					Sentry.captureException(error)
					setError('unknown')
				}
				setLoading(false)
			} else {
				setError('invalid-password')
			}
		}
	}

	let modalContent = <></>

	if (loading) {
		modalContent = (
			<div className="loading">
				<Loader />
			</div>
		)
	} else if (forgotPassword) {
		modalContent = (
			<>
				<div>
					<h5 className="fancy header-md">Forgot Password</h5>
					<p>Check your email for further instructions</p>
				</div>
				<Button
					type="tertiary"
					onClick={() => {
						setForgotPassword(false)
					}}
				>
					<span>Login</span>
				</Button>
			</>
		)
	} else {
		modalContent = (
			<>
				{!emailSubmitted && (
					<>
						<div>
							<h5 className="fancy header-md">Sign up / Log in</h5>
							<p>Save your work and access premium features.</p>
						</div>
						<div>
							<input
								placeholder="Enter your email address..."
								onChange={(e) => {
									setEmailInput(e.target.value)
									setError(undefined)
								}}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										submitForm()
									}
								}}
								value={emailInput}
								type="email"
								id="enter-email-address"
								autoFocus
							/>
						</div>
					</>
				)}
				{emailSubmitted && (
					<>
						<div>
							<div>
								<h5 className="fancy header-md">{hasLogin ? 'Enter your password' : 'Create your account'}</h5>
								<p>
									{hasLogin
										? 'Log into your ' + emailSubmitted + ' account.'
										: 'with the email ' + emailSubmitted + '.'}
								</p>
							</div>
						</div>
						<div>
							<input
								type="password"
								id="the-password"
								placeholder={hasLogin ? '' : 'Enter a password...'}
								value={passwordInput}
								onChange={(e) => {
									setPasswordInput(e.target.value)
									setError(undefined)
								}}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										submitForm()
									}
								}}
								autoFocus
							/>
							{hasLogin && (
								<p
									className="forgotPassword"
									onClick={() => {
										setForgotPassword(true)
										resetPassword(emailInput)
									}}
								>
									Forgot Password
								</p>
							)}
						</div>
					</>
				)}
				{error && <div className="error">{errorToText[error]}</div>}
				{
					<Button type="tertiary" onClick={submitForm}>
						<span>{emailSubmitted ? "Let's Go!" : 'Continue'}</span>
					</Button>
				}
			</>
		)
	}

	return (
		<>
			<Modal
				portalClassName="login"
				open={open}
				closeModal={() => {
					setModalOpen(false)
					setEmailInput('')
					setEmailSubmitted(undefined)
					dispatch(setUser(NULL_USER))
					Sentry.setUser(null)
				}}
			>
				{modalContent}
			</Modal>
		</>
	)
}

export default LoginModal
