import { initializeApp, getApps } from 'firebase/app'
import { getAuth } from 'firebase/auth'

getApps().length === 0 &&
	initializeApp({
		apiKey: 'AIzaSyCkNRDrDlnmAq5z1TT1Qfpem_b1eJHWcTc',
		authDomain: 'production-c058e.firebaseapp.com',
		projectId: 'production-c058e',
		storageBucket: 'production-c058e.appspot.com',
		messagingSenderId: '108231102386',
		appId: '1:108231102386:web:4ddf4485ca6f75e9689eab',
	})

export const auth = getAuth()
