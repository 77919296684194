export const logPurchase = () => {
	const itemID = Math.floor(Math.random() * 1000000).toString()
	window.gtag('event', 'purchase', {
		transaction_id: itemID + 'Trans',
		value: 5,
		currency: 'USD',
		tax: 0,
		shipping: 0,
		items: [
			{
				id: itemID,
				list_name: 'Premium Plan',
				brand: 'TGA',
				category: 'Subscription',
				quantity: 1,
				price: '5',
			},
		],
	})
}
