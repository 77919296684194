import { useEffect, useMemo, useState } from 'react'
import './_WriterOptions.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../redux/systemSlice'
import {
	CONSTANTS,
	TYPE_OPTIONS,
	TONE_OPTIONS,
	READING_LEVEL_OPTIONS,
	HUMANIZER_OPTIONS,
	DOC_TEMPLATES,
} from '../../constants'
import { selectDocumentState, setUserWriterSelections } from '../../redux/docSlice'
import Button from '../Buttons'
import Tooltip from '@mui/material/Tooltip'
import { isEqual } from 'lodash'
import { DocumentState, UserWriterSelections } from '../../redux/types'
import { blankUserWriterSelections } from '../../redux/constants'
import Subscriptions from '../Modals/Subscriptions'
import LoginModal from '../Modals/Login'
import Upsell from '../Modals/Upsell'

type Props = {
	type: string
	premiumUser: boolean
	formValid: boolean
	localUserWriterSelections: UserWriterSelections
	docID: string
}

function WriterOptions(props: Props) {
	const user = useSelector(selectUser)
	const dispatch = useDispatch()
	const [openOptions, setOpenOptions] = useState(false)
	const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const [upsellModalOpen, setUpsellModalOpen] = useState(false)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, props.docID))

	//Set redux if we have local storage for this document on component mount
	useEffect(() => {
		if (!isEqual(props.localUserWriterSelections, blankUserWriterSelections)) {
			dispatch(
				setUserWriterSelections({
					docID: props.docID,
					userWriterSelections: props.localUserWriterSelections,
				})
			)
		}
		//Update only on initial page load
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const pageCount = useMemo(() => {
		const wordCount = documentState.userWriterSelections?.wordCount ?? CONSTANTS.FREE_WORD_LIMIT
		return (wordCount / 500).toFixed(1)
	}, [documentState.userWriterSelections?.wordCount])

	return (
		<>
			<Subscriptions
				open={subscriptionModalOpen}
				closeModal={() => setSubscriptionModalOpen(false)}
				setLoginModal={(open: boolean) => {
					setLoginModalOpen(open)
				}}
				openUpsellModal={() => setUpsellModalOpen(true)}
			/>
			<LoginModal
				premiumPath={true}
				open={loginModalOpen}
				setModalOpen={(open: boolean) => {
					setLoginModalOpen(open)
				}}
				openUpsellModal={() => setUpsellModalOpen(true)}
			/>
			<Upsell
				openLoginModal={(open: boolean) => setLoginModalOpen(open)}
				isOpen={upsellModalOpen}
				closeModal={() => setUpsellModalOpen(false)}
			/>
			<div id="writerOptions">
				{props.type !== DOC_TEMPLATES.Outline && (
					<>
						<h4 className="sliderValue header-lg">
							<input
								onChange={(e) => {
									dispatch(
										setUserWriterSelections({
											docID: props.docID,
											userWriterSelections: { wordCount: parseInt(e.target.value) },
										})
									)
								}}
								onBlur={() => {
									const input = documentState.userWriterSelections?.wordCount ?? CONSTANTS.FREE_WORD_LIMIT
									if (input > CONSTANTS.WORD_COUNT_MAX) {
										dispatch(
											setUserWriterSelections({
												docID: props.docID,
												userWriterSelections: { wordCount: CONSTANTS.WORD_COUNT_MAX },
											})
										)
									} else if (input < CONSTANTS.WORD_COUNT_MIN) {
										dispatch(
											setUserWriterSelections({
												docID: props.docID,
												userWriterSelections: { wordCount: CONSTANTS.WORD_COUNT_MIN },
											})
										)
									}
								}}
								type="number"
								className="value"
								value={documentState.userWriterSelections?.wordCount ?? CONSTANTS.FREE_WORD_LIMIT}
								placeholder=""
							/>
							<span className="units fancy">
								words<span className="pageCount">, or ~{pageCount} pages</span>
							</span>
						</h4>
						<input
							type="range"
							min={CONSTANTS.WORD_COUNT_MIN}
							max={CONSTANTS.WORD_COUNT_MAX}
							className="slider"
							value={documentState.userWriterSelections?.wordCount ?? CONSTANTS.FREE_WORD_LIMIT}
							onChange={(e) => {
								dispatch(
									setUserWriterSelections({
										docID: props.docID,
										userWriterSelections: { wordCount: parseInt(e.target.value) },
									})
								)
							}}
						/>
						{user.planIdHumanizer && (
							<div className="humanizerContainer">
								<p>Select humanizer intensity: </p>
								<div className="toneTypeList">
									{HUMANIZER_OPTIONS.map((str, index) => {
										return (
											<Button
												className={`humanizerOption ${
													str === documentState.userWriterSelections?.humanizerIntensity && 'selected'
												}`}
												key={str + index}
												type="secondary"
												onClick={(e) => {
													dispatch(
														setUserWriterSelections({
															docID: props.docID,
															userWriterSelections: { humanizerIntensity: str },
														})
													)
												}}
											>
												<span>{str}</span>
											</Button>
										)
									})}
								</div>
							</div>
						)}
					</>
				)}
				<div className="toneTypeContainer">
					<p>Select an essay type: </p>
					<div className="toneTypeList">
						{TYPE_OPTIONS.map((str, index) => {
							return (
								<Button
									className={`toneTypeButton ${str === documentState.userWriterSelections?.type && 'selected'}`}
									key={str + index}
									type="primary"
									onClick={(e) => {
										dispatch(
											setUserWriterSelections({
												docID: props.docID,
												userWriterSelections: { type: str },
											})
										)
									}}
								>
									<span>{str}</span>
								</Button>
							)
						})}
					</div>
				</div>
				{props.type !== DOC_TEMPLATES.Outline && (
					<>
						<div className={`optionsContainer ${openOptions && 'show'}`}>
							{!props.premiumUser && (
								<div className="opaqueCover">
									<Button
										className={'premiumButton'}
										type={'secondary'}
										onClick={(e) => {
											setSubscriptionModalOpen(true)
										}}
									>
										<p className="fancy ">Access with Premium</p>
									</Button>
								</div>
							)}
							<div className="toneTypeContainer">
								<p>Select a reading level: </p>
								<div className="toneTypeList">
									{READING_LEVEL_OPTIONS.map((str, index) => {
										return (
											<Button
												className={`toneTypeButton ${
													str === documentState.userWriterSelections?.readingLevel && 'selected'
												}`}
												key={str + index}
												type="primary"
												onClick={(e) => {
													dispatch(
														setUserWriterSelections({
															docID: props.docID,
															userWriterSelections: { readingLevel: str },
														})
													)
												}}
											>
												<span>{str}</span>
											</Button>
										)
									})}
								</div>
							</div>
							<div className="toneTypeContainer">
								<p>Select an essay tone: </p>
								<div className="toneTypeList">
									{TONE_OPTIONS.map((str, index) => {
										return (
											<Button
												className={`toneTypeButton ${str === documentState.userWriterSelections?.tone && 'selected'}`}
												key={str + index}
												type="primary"
												onClick={(e) => {
													dispatch(
														setUserWriterSelections({
															docID: props.docID,
															userWriterSelections: { tone: str },
														})
													)
												}}
											>
												<span>{str}</span>
											</Button>
										)
									})}
								</div>
							</div>
							{(!props.premiumUser ||
								(documentState.userWriterSelections &&
									documentState.userWriterSelections.wordCount >= CONSTANTS.FREE_WORD_LIMIT)) && (
								<div
									className="refsContainer"
									onClick={(e) => {
										const includeRefs =
											documentState && documentState.userWriterSelections
												? documentState?.userWriterSelections?.includeRefs
													? false
													: true
												: false
										dispatch(
											setUserWriterSelections({
												docID: props.docID,
												userWriterSelections: {
													includeRefs: includeRefs,
												},
											})
										)
									}}
								>
									<Tooltip
										title="The AI model cannot ensure all references are accurate. Additionally, the model will not always use outside sources when constructing your essay."
										placement="top-start"
									>
										<h6 id="refsLabel" className="fancy header-sm">
											Include References?
										</h6>
									</Tooltip>

									<Button
										className={`refsCheckbox ${documentState.userWriterSelections?.includeRefs ? 'checked' : ''}`}
										type={'secondary'}
									>
										<p className="fancy disable">{documentState.userWriterSelections?.includeRefs ? 'X' : ' '}</p>
									</Button>
								</div>
							)}
						</div>
						<div className="expandButton">
							<Button
								type="primary"
								className="clickableExpandButton generateEssayButton"
								onClick={(e) => setOpenOptions(!openOptions)}
							>
								<span className="fancy">{openOptions ? 'Fewer options' : 'More options'}</span>
							</Button>
						</div>
					</>
				)}

				{props.formValid && (
					<div className={`topicDivider dividingBorder ${props.type === DOC_TEMPLATES.Outline && 'topMargin'}`}></div>
				)}
			</div>
		</>
	)
}

export default WriterOptions
