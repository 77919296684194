import React, { Suspense, lazy } from 'react'
import store from './redux/store'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Navbar from './Components/Navbar/Navbar'
import { CONSTANTS } from './constants'
import { CookiesProvider } from 'react-cookie'
import { AuthProvider } from './contexts/Auth'
import PrivateRoute from './Pages/PrivateRoute'
import Create from './Pages/Create/Create'
import Examples from './Pages/Examples'
import Post from './Pages/Post'
import Gallery from './Pages/Choose'
import Blog from './Pages/Blog'
import Unknown from './Pages/404'
import New from './Pages/New'
import { HelmetProvider } from 'react-helmet-async'
import Pricing from './Pages/Pricing'

export function getCookie(cname: string) {
	let name = cname + '='
	let decodedCookie = decodeURIComponent(document.cookie)
	let ca = decodedCookie.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) === ' ') {
			c = c.substring(1)
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length)
		}
	}
	return ''
}

export const contactFormLink = 'https://the-good-ai.helpscoutdocs.com'

function App() {
	return (
		<React.StrictMode>
			<Provider store={store}>
				<AuthProvider>
					<CookiesProvider>
						<HelmetProvider>
							<Routes />
						</HelmetProvider>
					</CookiesProvider>
				</AuthProvider>
			</Provider>
		</React.StrictMode>
	)
}

const Home = lazy(() => import('./Pages/Home/Home'))

const Routes = () => {
	return (
		<BrowserRouter>
			<Suspense fallback={<div></div>}>
				<Navbar />
				<Switch>
					<PrivateRoute
						loginRequired={false}
						component={(props: any) => (
							<Create type={props.match.params.type} docID={props.match.params.id ? atob(props.match.params.id) : ''} />
						)}
						path={CONSTANTS.CREATE_PATH + '/:type/:id'}
					/>
					<PrivateRoute loginRequired={false} path={CONSTANTS.DOC_PATH} component={() => <Gallery />} />
					<Route path="/post/:title" render={(props) => <Post path={props.match.params.title} />} />
					<Route path="/new/:template" render={(props) => <New template={props.match.params.template} />} />
					<Route path="/examples" render={() => <Examples />} />
					<Route path="/blog/:title" render={(props) => <Post path={props.match.params.title} />} />
					<Route path="/blog" render={() => <Blog />} />
					<Route path="/pricing" render={() => <Pricing />} />
					<Route exact path="/" render={() => <Home />} />
					<Route path="/404" component={Unknown} />
					<Redirect to="/404" />
				</Switch>
			</Suspense>
		</BrowserRouter>
	)
}

export default App
