import * as diff from 'diff'
import './_Diff.scss'

type DiffProps = {
	oldStr: string
	newStr: string
}

const Diff = ({ oldStr, newStr }: DiffProps) => {
	let groups: diff.Change[] = []
	groups = diff.diffWords(oldStr, newStr)

	const mappedNodes = groups.map((group: diff.Change, index: number) => {
		const { value, added, removed } = group
		return (
			<span key={value + index} className={added ? 'added' : removed ? 'removed' : ''}>
				{value}
			</span>
		)
	})

	return <span>{mappedNodes}</span>
}

export default Diff
