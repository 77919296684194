import { useSelector } from 'react-redux'
import { DocumentState } from '../../redux/types'
import { selectDocumentState } from '../../redux/docSlice'
import { useMemo } from 'react'
import { useDocId } from '../../hooks/docID'
import './_WordCount.scss'

const WordCount = () => {
	const docID = useDocId()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))

	const wordCount = useMemo(
		() =>
			documentState && documentState.content && documentState.content.length > 0
				? documentState.content
						.replace(/\s+/g, ' ')
						.split(' ')
						.filter((word: string) => {
							return word.length !== 0
						}).length
				: 0,
		[documentState]
	)

	if (documentState.isGenerating || documentState.isHumanizing || documentState.isTypingEssay) {
		return null
	}

	return (
		<>
			<span className="sm:mr-4 font-bold">
				{wordCount} <span className="units lowercase ml-0.5 font-normal">word{wordCount === 1 ? '' : 's'}</span>
			</span>
			<span className="font-bold sm:mr-5">
				{((documentState.content ? documentState.content.length : 0) / 2900).toFixed(2)}
				<span className="units font-normal"> pages</span>
			</span>
		</>
	)
}
export default WordCount
