import React from 'react'
import { useSelector } from 'react-redux'
import { useAuth } from '../../contexts/Auth'
import { selectUser } from '../../redux/systemSlice'
import Button from '../Buttons'
import { CONSTANTS } from '../../constants'

function Premium({
	closeParentModal,
	setLoginModal,
	openUpsellModal,
}: {
	closeParentModal: () => void
	setLoginModal: (open: boolean) => void
	openUpsellModal: () => void
}) {
	const user = useSelector(selectUser)
	const { currentUser } = useAuth() as any

	function openNextStep() {
		if (!currentUser) {
			setLoginModal(true)
		} else if (!user.planIdHumanizer) {
			setLoginModal(false)
			openUpsellModal()
		}
		closeParentModal()
	}

	return (
		<div className="premiumSection">
			<h6 className="bg-secondary header-sm">Premium Plan</h6>
			<p>{CONSTANTS.WORD_COUNT_MAX} Word Essays</p>
			<p>Advanced Essay Options</p>
			<p>AI Autocomplete</p>
			<p>Unlimited Essays</p>
			<p className="price">Only $5/month</p>

			{/* {token && !!user.planIdHumanizer && (
				<form action={process.env.REACT_APP_API_ROOT + '/payments/checkout/'} method="POST">
					<input type="hidden" name="token" value={token} />
					<input type="hidden" name="user_id" value={user.id} />
					<Basic type="secondary" CTA={<span>Get Started</span>} htmlType="submit" />
				</form>
			)} */}
			<Button type="secondary" htmlType="submit" onClick={openNextStep}>
				<span>Get Started</span>
			</Button>
		</div>
	)
}

export default Premium
