import { useDispatch } from 'react-redux'
import Button from '../Buttons'
import { setContent, setSetup, setSetupChanged } from '../../redux/docSlice'
import './_Revert.scss'
import Modal from './Modal'

function Revert({ docID, closeModal, isOpen }: { docID: string; closeModal: () => void; isOpen: boolean }) {
	const dispatch = useDispatch()

	function revertEssay(docID: string) {
		dispatch(setSetup({ docID, setup: false }))
		dispatch(setSetupChanged({ docID, setupChanged: true }))
		dispatch(setContent({ docID, content: '' }))
		closeModal()
	}

	return (
		<Modal open={isOpen} closeModal={closeModal} portalClassName="revert">
			<h5 className="header-md">Are you sure you want to restart?</h5>
			<p>Your current content will be lost.</p>
			<div className="choices">
				<Button
					type="secondary"
					onClick={() => {
						closeModal()
					}}
				>
					<span>No</span>
				</Button>
				<Button type="secondary" onClick={() => revertEssay(docID)}>
					<span>Yes</span>
				</Button>
			</div>
		</Modal>
	)
}

export default Revert
