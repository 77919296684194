import { useSelector } from 'react-redux'
import { useAuth } from '../contexts/Auth'
import { selectUser } from '../redux/systemSlice'

const useCheckout = () => {
	const { currentUser } = useAuth() as any
	const user = useSelector(selectUser)

	const checkout = async (humanizerUpgrade: boolean) => {
		if (!user) return
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({ uid: currentUser.uid, humanizerUpgrade }),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/payments/checkout/', requestOptions)
			.then((res) => res.json())
			.then((response: { url: string }) => {
				if (response.url) {
					window.open(response.url, '_self')
				}
			})
			.catch((err) => {
				console.error(err)
			})
	}

	return { checkout }
}

export default useCheckout
