import React, { useCallback, useContext, useEffect, useState } from 'react'
import { auth } from '../firebase'
import {
	onAuthStateChanged,
	User,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	updateEmail,
	EmailAuthProvider,
	reauthenticateWithCredential,
} from 'firebase/auth'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserLoading, setUser, setUserLoading } from '../redux/systemSlice'
import { NULL_USER } from '../constants'
import * as Sentry from '@sentry/react'
const AuthContext = React.createContext(null)

export function useAuth() {
	return useContext(AuthContext)
}

interface Props {
	children: React.ReactNode
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
	const dispatch = useDispatch()
	const userLoading = useSelector(selectUserLoading)
	const [currentUser, setCurrentUser] = useState<User | null>(null)

	function signup(email: string, password: string) {
		return createUserWithEmailAndPassword(auth, email, password)
	}

	function signin(email: string, password: string) {
		return signInWithEmailAndPassword(auth, email, password)
	}

	function resetPassword(email: string) {
		return sendPasswordResetEmail(auth, email)
	}

	//requires reauth credential from a recent sign in
	function updateUserEmail(email: string): any {
		if (currentUser === null) {
			Sentry.captureMessage('Invalid updateUserEmail call; currentUser is null')
			return
		}
		return updateEmail(currentUser, email)
	}

	function reauthenticateUserWithCredential(email: string, password: string) {
		if (currentUser === null) {
			Sentry.captureMessage('Invalid updateUserEmail call; currentUser is null')
			return
		}
		const credential = EmailAuthProvider.credential(email, password)
		return reauthenticateWithCredential(currentUser, credential)
	}

	function logout() {
		setCurrentUser(null)
		dispatch(setUser(NULL_USER))
		Sentry.setUser(null)
		return auth.signOut().then(() => {
			window.location.reload()
		})
	}

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			setCurrentUser(user)
			user?.email ? Sentry.setUser({ email: user.email }) : Sentry.setUser(null)
			dispatch(setUserLoading({ value: false }))
		})
		return unsubscribe
	}, [dispatch])

	const getUserInfo = useCallback(async () => {
		dispatch(setUserLoading({ value: true }))
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				uid: currentUser?.uid,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/user/retrieve/', requestOptions)
			.then((res) => res.json())
			.then((result: any) => {
				if (result) {
					dispatch(setUser(result))
					Sentry.setUser({ email: result.email })
					dispatch(setUserLoading({ value: false }))
				} else {
					dispatch(setUser(NULL_USER))
					Sentry.setUser(null)
					setCurrentUser(null)
					dispatch(setUserLoading({ value: false }))
				}
			})
			.catch((e) => {
				dispatch(setUser(NULL_USER))
				dispatch(setUserLoading({ value: false }))
				setCurrentUser(null)
				Sentry.captureException(e)
				window.location.reload()
			})
	}, [currentUser?.uid, dispatch])

	useEffect(() => {
		if (currentUser) {
			getUserInfo()
			dispatch(setUserLoading({ value: false }))
		}
	}, [currentUser?.uid, dispatch, getUserInfo])

	const value = {
		currentUser,
		signup,
		signin,
		logout,
		resetPassword,
		updateUserEmail,
		reauthenticateUserWithCredential,
		setCurrentUser: (user: User) => {
			setCurrentUser(user)
		},
	}

	return <AuthContext.Provider value={value as any}>{!userLoading && children}</AuthContext.Provider>
}
