import { useSelector } from 'react-redux'
import { useAuth } from '../../contexts/Auth'
import { useToken } from '../../hooks/token'
import { selectUser } from '../../redux/systemSlice'
import Button from '../Buttons'
import useCheckout from '../../hooks/checkout'
import './_Upsell.scss'
import Modal from './Modal'

function Upsell({
	isOpen,
	closeModal,
	openLoginModal,
}: {
	isOpen: boolean
	closeModal: () => void
	openLoginModal?: (open: boolean) => void
}) {
	const { checkout } = useCheckout()
	const user = useSelector(selectUser)
	const { currentUser } = useAuth() as any
	const token = useToken()

	function _openLoginModal() {
		closeModal()
		if (openLoginModal) {
			openLoginModal(true)
		}
	}
	return (
		<Modal open={isOpen} closeModal={closeModal} portalClassName="upsell" omitClose>
			<div>
				<div className="free">
					<h6 className="header-sm highlighted">AI Detector and Humanizer</h6>
					<p className="mb-2 mt-3">
						Would you like to add our AI Detector and Humanizer integration for $3 more a month? Provided by{' '}
						<a href="https://the-ghost-ai.com" target="_blank" rel="noreferrer" className="font-semibold">
							Ghost AI
						</a>
						.
					</p>
					<p className="mt-3 mb-2">Bypass AI detectors and detect AI generated content.</p>
					<p>
						<a
							href="https://the-good-ai.helpscoutdocs.com/article/9-how-to-use-ai-humanizer-and-detector"
							target="_blank"
							rel="noreferrer"
							className="text-link"
						>
							Learn more
						</a>
					</p>
				</div>
				{!!token && (
					<Button type="secondary" className="confirmButton" onClick={() => checkout(true)}>
						<span>Yes, please</span>
					</Button>
				)}
				{!currentUser && (
					<Button type="secondary" htmlType="submit" onClick={_openLoginModal} className="confirmButton">
						<span>Yes, please</span>
					</Button>
				)}
				{!user.planId && token && (
					<Button type="secondary" onClick={checkout}>
						<span>No, thanks</span>
					</Button>
				)}
				{user.planId && currentUser && (
					<Button
						type="secondary"
						onClick={() => {
							closeModal()
						}}
					>
						<span>No, thanks</span>
					</Button>
				)}
				{!currentUser && (
					<Button type="secondary" onClick={_openLoginModal}>
						<span>No, thanks</span>
					</Button>
				)}
			</div>
		</Modal>
	)
}

export default Upsell
