import './_Create.scss'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '../../Components/Buttons'
import { CONSTANTS, DOC_TEMPLATES } from '../../constants'
import TextareaAutosize from 'react-textarea-autosize'
import { selectDocumentState } from '../../redux/docSlice'
import { selectUser } from '../../redux/systemSlice'
import WriterOptions from '../../Components/WriterOptions/WriterOptions'
import { useLocalStorage } from '../../helpers/utility'
import { DocumentState } from '../../redux/types'
import { blankUserWriterSelections } from '../../redux/constants'
import SubTopics from './Subtopics'
import useWriteEssay from '../../hooks/useWriteEssay'
import ErrorModal from '../../Components/Modals/Error'

function Setup({
	type,
	docID,
	setSubscriptionModalOpen,
}: {
	type: keyof typeof DOC_TEMPLATES | undefined
	docID: string
	setSubscriptionModalOpen: (open: boolean) => void
}) {
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const [topicValue, setTopicValue] = useState<string>(documentState?.title ?? '')
	const [topicInputFocused, setTopicInputFocused] = useState<boolean>(false)
	const user = useSelector(selectUser)
	const formValid = topicValue !== undefined && topicValue.length !== 0
	const [localUserWriterSelections, setLocalUserWriterSelections] = useLocalStorage(docID, blankUserWriterSelections)

	const [errorModalOpen, setErrorModalOpen] = useState(false)
	const { generateEssay, getSubtopics } = useWriteEssay({
		docID: docID,
		type: type,
		topicValue,
		setTopicValue,
		setLocalUserWriterSelections,
		openSubscriptionModal: () => setSubscriptionModalOpen(true),
		setErrorModalOpen: setErrorModalOpen,
	})

	useEffect(() => {
		setTopicValue(documentState?.title ?? '')
	}, [documentState?.title])

	if (!!documentState.setup || !type) {
		return null
	}

	return (
		<>
			<ErrorModal open={errorModalOpen} closeModal={() => setErrorModalOpen(false)} />
			<div className="headerContainer">
				{!documentState.isOnSubtopicsStep && (
					<TextareaAutosize
						placeholder="Type your topic..."
						id="topicHeader"
						autoFocus
						onFocus={() => {
							setTopicInputFocused(true)
						}}
						onBlur={() => {
							setTopicInputFocused(false)
						}}
						onChange={(e) => {
							setTopicValue(e.target.value)
						}}
						minRows={1}
						maxRows={topicInputFocused ? undefined : 3}
						maxLength={CONSTANTS.MAX_TOPIC_LENGTH}
						value={topicValue}
					/>
				)}
				{!documentState.isOnSubtopicsStep && (
					<>
						{documentState.isGeneratingSubtopics ? (
							<div className="topicDivider contentGenerating"></div>
						) : (
							<div className="topicDivider dividingBorder"></div>
						)}
					</>
				)}
				{!documentState.isOnSubtopicsStep && (
					<WriterOptions
						type={type!}
						premiumUser={!!user.planId}
						formValid={formValid}
						localUserWriterSelections={localUserWriterSelections}
						docID={docID}
					/>
				)}
				{formValid && !documentState.isOnSubtopicsStep && (
					<Button
						type="secondary"
						onClick={
							documentState.userWriterSelections.wordCount > CONSTANTS.TOPIC_GENERATION_BREAKPOINT && user.planId
								? getSubtopics
								: generateEssay
						}
						className="generateEssayButton"
					>
						<span>
							{type === DOC_TEMPLATES.Writer
								? documentState.userWriterSelections.wordCount > CONSTANTS.TOPIC_GENERATION_BREAKPOINT && user.planId
									? 'Choose subtopics'
									: 'Generate Essay'
								: 'Create Outline'}
						</span>
					</Button>
				)}
				{documentState.isOnSubtopicsStep && <SubTopics generateEssay={generateEssay} />}
			</div>
		</>
	)
}

export default Setup
