import Loader from '../Loader'
import Modal from './Modal'
import './_Thanks.scss'

function Thanks({ open, closeModal }: { open: boolean; closeModal: () => void }) {
	return (
		<Modal open={open} closeModal={closeModal} portalClassName="thanks">
			<>
				<Loader />
				<h5 className="header-md">Thank you!</h5>
				<p>Your purchase was successful.</p>
			</>
		</Modal>
	)
}

export default Thanks
