import Button from '../Buttons'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import './_Detector.scss'
import { selectUser } from '../../redux/systemSlice'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { DocumentState } from '../../redux/types'
import { selectDocumentState } from '../../redux/docSlice'
import { htmlToString } from '../../helpers/utility'
import { useDocId } from '../../hooks/docID'
import { useAuth } from '../../contexts/Auth'
import { useState } from 'react'
import DetectionModal from '../Modals/Detection'
import Upsell from '../Modals/Upsell'
import { CONSTANTS } from '../../constants'

const Detector = () => {
	const [detectionModalOpen, setDetectionModalOpen] = useState(false)
	const [detectionLoading, setDetectionLoading] = useState(false)
	const [detectionResult, setDetectionResult] = useState<number | null>(null)
	const [upsellModalOpen, setUpsellModalOpen] = useState(false)
	const docID = useDocId()
	const { currentUser } = useAuth() as any
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))
	const user = useSelector(selectUser)

	//Get current document id from url if we are on create page

	const handleDetector = async () => {
		if (!user.planIdHumanizer) {
			setUpsellModalOpen(true)
			return
		}

		setDetectionLoading(true)
		setDetectionModalOpen(true)

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				userId: user.id,
				text: htmlToString(documentState.content),
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/detect/', requestOptions)
			.then((res) => res.json())
			.then((response: number) => {
				setDetectionResult(response)
				setDetectionLoading(false)
			})
			.catch((err) => {
				Sentry.captureException(err)
			})
	}
	return (
		<>
			<Upsell isOpen={upsellModalOpen} closeModal={() => setUpsellModalOpen(false)} />
			<DetectionModal
				open={detectionModalOpen}
				closeModal={() => setDetectionModalOpen(false)}
				loading={detectionLoading}
				result={detectionResult}
			/>
			<div className="detectorContainer">
				<Button
					type="tertiary"
					disabled={
						documentState &&
						(documentState.isHumanizing ||
							documentState.isGenerating ||
							documentState.isTypingAddMore ||
							documentState.isTypingEssay ||
							documentState.content === '' ||
							documentState.content === CONSTANTS.EMPTY_DOCUMENT)
					}
					onClick={handleDetector}
				>
					<div className="iconAndText">
						<TrackChangesIcon className={`detectIcon`} />
						<div>Check for AI</div>
					</div>
				</Button>
			</div>
		</>
	)
}

export default Detector
