import './_Loader.scss'

function Loader() {
	return (
		<div className="spinContainer">
			<div className="spinner">
				<span></span>
			</div>
		</div>
	)
}

export default Loader
