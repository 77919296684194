import { CONSTANTS } from '../../constants'
import Premium from '../Premium/Premium'
import Modal from './Modal'
import './_Subscriptions.scss'

function Subscriptions({
	open,
	closeModal,
	setLoginModal,
	openUpsellModal,
}: {
	open: boolean
	closeModal: () => void
	setLoginModal: (open: boolean) => void
	openUpsellModal: () => void
}) {
	return (
		<Modal open={open} closeModal={closeModal} portalClassName="subscription">
			<div className="flex-container">
				<div className="free">
					<h6 className="header-sm">Free Plan</h6>
					<p>{CONSTANTS.FREE_WORD_LIMIT} Word Limit</p>
					<p>Basic Essay Options</p>
					<p>No AI Autocomplete</p>
				</div>
				<div className="pro">
					{<Premium closeParentModal={closeModal} setLoginModal={setLoginModal} openUpsellModal={openUpsellModal} />}
				</div>
			</div>
		</Modal>
	)
}

export default Subscriptions
