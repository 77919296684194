import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Link, useHistory } from 'react-router-dom'
import { setLastAggregatePageVisited } from '../redux/systemSlice'
import { DOC_TEMPLATES } from '../constants'
import UserDocs from '../Components/UserDocs/UserDocs'
import Thanks from '../Components/Modals/Thanks'
import { logPurchase } from '../helpers/analytics'

export type templateType = {
	id: string
	title: string
	label: string
	desc: string
}
export const options: templateType[] = [
	{
		id: DOC_TEMPLATES.Writer,
		title: 'AI Essay Writer',
		label: ' — lots of help',
		desc: 'Choose a topic, word count, tone, reading level, and more.',
	},
	{
		id: DOC_TEMPLATES.Outline,
		title: 'AI Essay Outliner',
		label: ' — some help',
		desc: "Choose a topic and we'll generate an outline for you to complete.",
	},
	{
		id: DOC_TEMPLATES.Blank,
		title: 'Blank Document',
		label: ' — little help',
		desc: 'Start yourself and use our autocomplete tool to guide you.',
	},
]

const pageName = 'doc'

function Doc() {
	const [thanksModalOpen, setThanksModalOpen] = useState(false)
	const searchParamsString = document.location.search
	const searchParams = useMemo(() => {
		let params = new URLSearchParams(searchParamsString)

		return {
			success: params.getAll('success')[0] === 'true',
			sessionID: params.getAll('session_id')[0],
			showModal: params.getAll('showModal')[0],
		}
	}, [searchParamsString])
	const dispatch = useDispatch()
	const history = useHistory()

	useEffect(() => {
		if (searchParams.showModal) {
			setThanksModalOpen(true)
		}
	}, [searchParams, dispatch])

	useEffect(() => {
		dispatch(setLastAggregatePageVisited({ value: '/choose' }))
	}, [dispatch])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<>
			<Helmet>
				<title>Choose an Option</title>
			</Helmet>
			<Thanks
				open={thanksModalOpen}
				closeModal={() => {
					setThanksModalOpen(false)
					logPurchase()
					history.push('/choose')
				}}
			/>
			<main id={pageName} className="px-2 lg:px-56 pt-16 max-w-[1400px] mx-auto">
				<div className="mb-6">
					<UserDocs />
				</div>
				<h5 className="md:text-4xl text-center my-3 text-3xl">Choose an option</h5>
				<hr className="border-t border-primary border-dotted mb-12" />
				<div>
					{options.map((item, index) => {
						return (
							<Link
								className="cursor-pointer mb-12 py-2 px-3 flex justify-between items-center text-primary hover:bg-secondary transition-colors group"
								to={'/new/' + item.id}
								key={'option' + index}
							>
								<div className="flex flex-col gap-2">
									<h5 className="text-3xl">
										{item.title}
										<span className="fancy">{item.label}</span>
									</h5>
									<p className="m-0 text-gray-700">{item.desc}</p>
								</div>
								<span className="relative bottom-1 transition-all text-5xl opacity-0 group-hover:opacity-100 group-hover:right-0 right-5">
									⤑
								</span>
							</Link>
						)
					})}
				</div>
			</main>
		</>
	)
}

export default Doc
