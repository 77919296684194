import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { setLastAggregatePageVisited } from '../redux/systemSlice'
import { Post } from './Examples'
import './_Blog.scss'
import * as Sentry from '@sentry/react'
import { Helmet } from 'react-helmet-async'
import Footer from '../Components/Footer'

function Blog() {
	const pageName = 'blog'
	const [posts, setPosts] = useState<Post[]>([])
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setLastAggregatePageVisited({ value: '/blog' }))
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/cms/blog-list/', requestOptions)
			.then((res) => res.json())
			.then((data: Post[]) => {
				setPosts(data)
			})
			.catch((err) => Sentry.captureException(err))
	}, [dispatch])

	function getPreview(post: Post, type: 'small' | 'medium' | 'large') {
		let image = post.image ? <img src={post.image} alt={post.title} /> : <></>
		let title
		let preview = <></>
		const tileClassName = 'header'
		switch (type) {
			case 'small':
				title = <h6 className={`${tileClassName} header-sm`}>{post.title}</h6>
				if (preview) {
					preview = <p>{post.preview}</p>
				}
				break
			case 'medium':
				title = <h5 className={`${tileClassName} header-md`}>{post.title}</h5>
				if (preview) {
					preview = <p>{post.preview}</p>
				}
				break
			case 'large':
				title = <h4 className={`${tileClassName} header-lg`}>{post.title}</h4>
				if (preview) {
					preview = <p>{post.preview}</p>
				}
		}
		if (type === 'small') {
			return (
				<Link className={'postPreview ' + type} to={'/post/' + post.path}>
					<div className="image">{image}</div>
					<div className="text">
						{title}
						{preview}
					</div>
				</Link>
			)
		}
		return (
			<Link className={'postPreview ' + type} to={'/post/' + post.path}>
				{image}
				{title}
				{preview}
			</Link>
		)
	}
	return (
		<>
			<Helmet>
				<title>The Good AI | AI Generated Blog</title>
				<meta
					name="description"
					content="The Good AI Blog is a blog written by an AI. All articles are generated by an AI trained on the internet."
				/>
				<meta name="keywords" content="blog, ai, artificial intelligence, machine learning, neural network" />
			</Helmet>
			<main id={pageName}>
				<div className="paddedContainer">
					<h4 className="pageHeader header-lg">
						<span className="highlighted fancy">The Good Blog</span>
					</h4>
					<p className="disclaimer">All articles written by A.I.</p>
					<hr />
					{posts.length > 1 && (
						<div className="topSection">
							<div className="section1">{getPreview(posts[0], 'large')}</div>
							<div className="section2">
								{getPreview(posts[1], 'medium')}
								<hr />
								{getPreview(posts[2], 'medium')}
							</div>
						</div>
					)}
					<hr />
					<div className="overflowPosts">
						{posts.length > 3 && posts.slice(3, posts.length).map((post, index) => getPreview(post, 'small'))}
					</div>
				</div>
			</main>
			<Footer />
		</>
	)
}

export default Blog
