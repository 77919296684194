import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CONSTANTS, DOC_TEMPLATES } from '../../constants'
import { useAuth } from '../../contexts/Auth'
import TextareaAutosize from 'react-textarea-autosize'
import * as Sentry from '@sentry/react'
import {
	selectDocumentState,
	setContent,
	setTitle,
	setIsGenerating,
	setIsTypingEssay,
	setIsHumanizing,
	setNeedsHumanizing,
} from '../../redux/docSlice'
import { selectUser } from '../../redux/systemSlice'
import './_Create.scss'
import { useCookieUser } from '../../hooks/cookieUser'
import { DocumentState } from '../../redux/types'
import AddMore from '../../Components/AddMore/AddMore'
import DocumentWrapper from '../../Components/DocumentWrapper'
import Setup from './Setup'
import ErrorModal from '../../Components/Modals/Error'
import Subscriptions from '../../Components/Modals/Subscriptions'
import LoginModal from '../../Components/Modals/Login'
import Upsell from '../../Components/Modals/Upsell'
import { useAtom } from 'jotai'
import { accountModalAtom } from './Create'

type CreateProps = {
	type: keyof typeof DOC_TEMPLATES | undefined
	docID: string
}

function Essay(props: CreateProps) {
	const { userIDCookie } = useCookieUser()
	const { currentUser } = useAuth() as any
	const dispatch = useDispatch()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, props.docID))
	const [topicInputFocused, setTopicInputFocused] = useState<boolean>(false)
	const [loadingDots, setLoadingDots] = useState<string>('.')
	const [errorModalOpen, setErrorModalOpen] = useState(false)
	const [accountModalState, setAccountModalState] = useAtom(accountModalAtom)
	const user = useSelector(selectUser)
	const documentRef = useRef<HTMLDivElement>(null)
	const headerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		let intervalId: any

		if (documentState && (documentState.isHumanizing || documentState.isGenerating)) {
			intervalId = setInterval(() => {
				// Update dots: cycle from '' -> '.' -> '..' -> '...'
				setLoadingDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''))
			}, 500) // Update every 500 milliseconds
		} else {
			setLoadingDots('') // Reset dots when loading is false
		}

		return () => clearInterval(intervalId) // Cleanup on unmount or loading changes
	}, [documentState?.isHumanizing, documentState?.isGenerating])

	useEffect(() => {
		if (
			documentState?.needsHumanizing &&
			!documentState.isTypingEssay &&
			!documentState.isGenerating &&
			!documentState.isHumanizing
		) {
			humanizeEssay(documentState.content)
		}
	}, [documentState?.needsHumanizing, documentState?.isTypingEssay, documentState?.content])

	async function makeHumanizerRequest(essayContent: string) {
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				text: essayContent,
				humanizerIntensity: documentState.userWriterSelections?.humanizerIntensity,
				token: currentUser ? await currentUser.getIdToken() : undefined,
				userID: user.id ?? userIDCookie,
			}),
		}

		try {
			// Await the fetch call and its conversion to JSON
			const response = await fetch(process.env.REACT_APP_API_ROOT + '/essay/humanize/', requestOptions)
			const result = await response.json()
			return result
		} catch (e) {
			Sentry.captureException(e)
			setErrorModalOpen(true)
			// Ensure that in case of error, the function returns a fallback or error indicator
			return '' // Consider returning null or a specific error object depending on your handling logic
		}
	}

	async function humanizeEssay(essayContent: string) {
		dispatch(setIsHumanizing({ docID: props.docID, isHumanizing: true }))

		let chunks = []

		if (essayContent.length > 1250 * 5) {
			// split the essay into two chunks based on newlines
			const splitContent = essayContent.split('\n')
			const mid = Math.floor(splitContent.length / 2)
			const firstHalf = splitContent.slice(0, mid).join('\n')
			const secondHalf = splitContent.slice(mid).join('\n')
			chunks = [firstHalf, secondHalf]
		} else {
			chunks = [essayContent]
		}

		let humanizedContent = ''

		for (let i = 0; i < chunks.length; i++) {
			const result = await makeHumanizerRequest(chunks[i])
			humanizedContent += result
		}

		dispatch(setContent({ docID: props.docID, content: humanizedContent }))
		dispatch(setIsGenerating({ docID: props.docID, isGenerating: false }))
		dispatch(setIsHumanizing({ docID: props.docID, isHumanizing: false }))
		dispatch(setIsTypingEssay({ docID: props.docID, isTypingEssay: false }))
		dispatch(setNeedsHumanizing({ docID: props.docID, needsHumanizing: false }))
	}

	return (
		<>
			<ErrorModal open={errorModalOpen} closeModal={() => setErrorModalOpen(false)} />
			<Subscriptions
				open={accountModalState.subscriptionModalOpen}
				closeModal={() => setAccountModalState((prev) => ({ ...prev, subscriptionModalOpen: false }))}
				setLoginModal={(open: boolean) => {
					setAccountModalState((prev) => ({ ...prev, loginModalOpen: open }))
				}}
				openUpsellModal={() => setAccountModalState((prev) => ({ ...prev, upsellModalOpen: true }))}
			/>
			<LoginModal
				premiumPath={true}
				open={accountModalState.loginModalOpen}
				setModalOpen={(open: boolean) => {
					setAccountModalState((prev) => ({ ...prev, loginModalOpen: open }))
				}}
				openUpsellModal={() => setAccountModalState((prev) => ({ ...prev, upsellModalOpen: true }))}
			/>
			<Upsell
				openLoginModal={(open: boolean) => setAccountModalState((prev) => ({ ...prev, loginModalOpen: open }))}
				isOpen={accountModalState.upsellModalOpen}
				closeModal={() => setAccountModalState((prev) => ({ ...prev, upsellModalOpen: false }))}
			/>
			<div id="create" className="veryPaddedContainer">
				{documentState && (
					<Setup
						type={props.type}
						docID={props.docID}
						setSubscriptionModalOpen={(open) =>
							setAccountModalState((prev) => ({ ...prev, subscriptionModalOpen: open }))
						}
					/>
				)}
				{documentState && documentState.setup && (
					<>
						<div className="headerContainer" ref={headerRef}>
							<TextareaAutosize
								placeholder="Untitled"
								id="topicHeader"
								onChange={(e) => {
									dispatch(setTitle({ docID: props.docID, title: e.target.value }))
								}}
								onFocus={() => {
									setTopicInputFocused(true)
								}}
								onBlur={() => {
									setTopicInputFocused(false)
								}}
								minRows={1}
								maxRows={topicInputFocused ? undefined : 3}
								maxLength={CONSTANTS.MAX_TOPIC_LENGTH}
								value={documentState.title}
							/>
							{documentState.isGenerating ||
							documentState.isHumanizing ||
							documentState.isTypingEssay ||
							documentState.isTypingAddMore ? (
								<div className="topicDivider contentGenerating"></div>
							) : (
								<div className="topicDivider dividingBorder"></div>
							)}
						</div>
					</>
				)}
				<div ref={documentRef}>
					{documentState && documentState.isHumanizing && (
						<div className="humanizingText">
							<h6 className="header-sm">Humanizing{loadingDots}</h6>
							<p>This may take up to a minute.</p>
						</div>
					)}
					{documentState && documentState.isGenerating && (
						<div className="humanizingText">
							<h6 className="header-sm">Writing essay{loadingDots}</h6>
							<p>This may take up to a minute.</p>
						</div>
					)}
					<div className={documentState && documentState.isHumanizing ? 'humanizing' : ''}>
						<DocumentWrapper docID={props.docID} type={props.type} />
					</div>
				</div>

				{documentState && documentState.setup && (
					<AddMore
						type={props.type}
						docID={props.docID}
						// During content typing, toggle addMore buttons between sticky and fixed
						isFixed={
							documentState.isTypingAddMore && documentRef.current && headerRef.current
								? documentRef.current.getBoundingClientRect().height +
										headerRef.current.getBoundingClientRect().height +
										250 >
								  window.innerHeight
								: false
						}
					/>
				)}
				{/* {documentState?.content && !documentState.isGenerating && documentState.isTypingEssay && (
				<Basic
					onClick={() => {
						if (reader) reader.cancel()
					}}
					type="tertiary"
					CTA={<span>Stop generating</span>}
					className="abortButton"
				/>
			)} */}
			</div>
		</>
	)
}

export default Essay
