import React from 'react'
import './_index.scss'

type buttonProps = {
	children: React.ReactNode
	type: 'primary' | 'secondary' | 'tertiary'
	onClick?: (e?: any) => void
	onMouseDown?: (e?: any) => void
	disabled?: boolean
	className?: string
	htmlType?: 'button' | 'submit' | 'reset' | undefined
	style?: React.CSSProperties
}

function Button(props: buttonProps) {
	const disabledClassName = props.disabled ? ' disabled ' : ''
	const button = (
		<button
			onClick={() => {
				if (props.onClick && !props.disabled) {
					props.onClick()
				}
			}}
			onMouseDown={() => {
				if (props.onMouseDown && !props.disabled) {
					props.onMouseDown()
				}
			}}
			className={props.type + 'Button ' + disabledClassName + (props.className ? props.className : '')}
			type={props.htmlType ?? 'button'}
			style={props.style}
		>
			{props.children}
		</button>
	)
	return button
}

export default Button
