import { useCallback, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import {
	selectUser,
	selectUserLoading,
	selectUserPagesCache,
	selectUserPagesLoading,
	setUserPagesCache,
	setUserPagesLoading,
} from '../../redux/systemSlice'
import * as Sentry from '@sentry/react'
import { useAuth } from '../../contexts/Auth'
import { Skeleton, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { CONSTANTS } from '../../constants'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import './_UserDocs.scss'
import Delete from '../Modals/Delete'
import ExistingDocsModal from '../Modals/Docs'

export type ExistingDoc = {
	title: string | null
	template: 0 | 1 | 2
	id: number
}

const UserDocs = () => {
	const [userDocs, setUserDocs] = useState<ExistingDoc[]>([])
	const [deleteModalOpen, setDeleteModalOpen] = useState(false)
	const [deleteDocId, setDeleteDocId] = useState(0)
	const [deleteDocTitle, setDeleteDocTitle] = useState('')
	const [existingDocsModalOpen, setExistingDocsModalOpen] = useState(false)
	const [cookies] = useCookies(['docs'])
	const userLoading = useSelector(selectUserLoading)
	const userPagesCache = useSelector(selectUserPagesCache)
	const userPagesLoading = useSelector(selectUserPagesLoading)
	const { currentUser } = useAuth() as any
	const user = useSelector(selectUser)

	const dispatch = useDispatch()

	const getCookieDocuments = useCallback(
		async (existingCookieDocs: any) => {
			dispatch(setUserPagesLoading({ value: true }))
			if (userPagesCache.source === 'cookie' && !userPagesCache.dirty) {
				setUserDocs(userPagesCache.pages)
				dispatch(setUserPagesLoading({ value: false }))
				return
			}
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ docs: existingCookieDocs }),
			}
			fetch(process['env']['REACT_APP_API_ROOT'] + '/document/guest-list/', requestOptions)
				.then((res) => res.json())
				.then((result: ExistingDoc[]) => {
					if (result) {
						setUserDocs(result)
						dispatch(setUserPagesCache({ source: 'cookie', pages: result, dirty: false }))
					}
					dispatch(setUserPagesLoading({ value: false }))
				})
				.catch((e) => {
					Sentry.captureException(e)
					dispatch(setUserPagesLoading({ value: false }))
				})
		},
		[dispatch, userPagesCache]
	)

	const getUserDocuments = useCallback(async () => {
		if (currentUser && user.id) {
			dispatch(setUserPagesLoading({ value: true }))
			if (userPagesCache.source === 'auth' && !userPagesCache.dirty) {
				setUserDocs(userPagesCache.pages)
				dispatch(setUserPagesLoading({ value: false }))
				return
			}
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
				},
				body: JSON.stringify({
					userId: user.id,
				}),
			}
			fetch(process['env']['REACT_APP_API_ROOT'] + '/document/list/', requestOptions)
				.then((res) => res.json())
				.then((result: ExistingDoc[]) => {
					if (result) {
						setUserDocs(result)
						dispatch(setUserPagesCache({ source: 'auth', pages: result, dirty: false }))
					}
					dispatch(setUserPagesLoading({ value: false }))
				})
				.catch((e) => {
					Sentry.captureException(e)
					dispatch(setUserPagesLoading({ value: false }))
				})
		}
	}, [currentUser, dispatch, userPagesCache, user])

	useEffect(() => {
		if (userLoading) {
			return
		}
		let existingCookieDocs = cookies.docs
		if (existingCookieDocs && existingCookieDocs.length && !currentUser) {
			getCookieDocuments(existingCookieDocs)
		} else if (existingCookieDocs && !currentUser) {
			setUserDocs([])
		} else if (currentUser) {
			getUserDocuments()
		}
	}, [currentUser, user, cookies.docs, getCookieDocuments, getUserDocuments, userLoading])

	if (userPagesLoading) {
		return (
			<Stack direction="row" spacing={1} paddingTop="10px">
				{[0, 1, 2].map((index) => (
					<Skeleton variant="rectangular" key={index} height={26} width={100} style={{ borderRadius: '17px' }} />
				))}
			</Stack>
		)
	}
	if (!userDocs.length) {
		return <></>
	}
	return (
		<>
			{!!deleteDocId && (
				<Delete
					isOpen={deleteModalOpen}
					id={deleteDocId}
					title={deleteDocTitle}
					closeModal={() => {
						setDeleteModalOpen(false)
						setDeleteDocId(0)
						setDeleteDocTitle('')
					}}
				/>
			)}
			<ExistingDocsModal isOpen={existingDocsModalOpen} closeModal={() => setExistingDocsModalOpen(false)} />
			<div className="existingDocsHeader flex flex-row overflow-x-auto w-100 py-3 flex-nowrap mb-0 gap-2">
				{userDocs.length > 3 && (
					<div
						className="viewAll flex items-center whitespace-nowrap border border-primary hover:bg-secondary text-primary cursor-pointer px-3 rounded-full leading-snug"
						onClick={() => setExistingDocsModalOpen(true)}
					>
						<FormatListBulletedIcon fontSize="inherit" className="mr-2" />
						<div>View all</div>
					</div>
				)}
				<div className="flex gap-2 overflow-x-auto overflow-y-hidden">
					{userDocs.map((doc: ExistingDoc, index: number) => {
						return (
							<Link
								to={CONSTANTS.CREATE_PATH + '/' + doc.template + '/' + btoa(doc.id.toString())}
								key={index + 'doc'}
								className="group border border-primary border-dotted hover:border-solid hover:bg-secondary text-primary cursor-pointer px-3 hover:pl-2 rounded-full whitespace-nowrap leading-snug"
							>
								<span
									className="px-2 pb-0.5 border border-primary rounded-full cursor-pointer border-dotted fancy hover:bg-primary hover:text-white bg-white relative right-[9px] bottom-[.5px] hidden group-hover:inline"
									onClick={(e) => {
										e.preventDefault()
										setDeleteDocId(doc.id)
										setDeleteDocTitle(doc.title ?? '')
										setDeleteModalOpen(true)
									}}
								>
									x
								</span>
								<span className="doc">
									{!!doc?.title?.length
										? doc.title.length > CONSTANTS.MAX_DOCUMENT_DISPLAY_LENGTH
											? doc.title.slice(0, CONSTANTS.MAX_DOCUMENT_DISPLAY_LENGTH) + '...'
											: doc.title
										: 'Untitled'}
								</span>
							</Link>
						)
					})}
				</div>
			</div>
		</>
	)
}

export default UserDocs
