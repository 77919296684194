import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../Components/Loader'
import { atom } from 'jotai'
import { useAuth } from '../../contexts/Auth'
import {
	createNewDocument,
	setContent,
	setLastSavedContent,
	setLastSavedTitle,
	setSetup,
	setTitle,
} from '../../redux/docSlice'
import { selectUser, selectUserLoading, setLastAggregatePageVisited, setShowNav } from '../../redux/systemSlice'
import Essay from './Essay'
import './_Create.scss'
import * as Sentry from '@sentry/react'
import { useCookieUser } from '../../hooks/cookieUser'
import { DOC_TEMPLATES } from '../../constants'

type CreateProps = {
	type: keyof typeof DOC_TEMPLATES | undefined
	docID: string
}

type completeDoc = {
	title: string
	content: string
	setup: boolean
}

const pageName = 'create'

type ModalAtomSchema = {
	subscriptionModalOpen: boolean
	loginModalOpen: boolean
	upsellModalOpen: boolean
}

const defaultValues: ModalAtomSchema = {
	subscriptionModalOpen: false,
	loginModalOpen: false,
	upsellModalOpen: false,
}

export const accountModalAtom = atom<ModalAtomSchema>(defaultValues)

function Create(props: CreateProps) {
	const { userIDCookie } = useCookieUser()
	const [invalid, setInvalid] = useState(false)
	const [loading, setLoading] = useState(true)
	const [fetched, setFetched] = useState(false)
	const { currentUser } = useAuth() as any
	const dispatch = useDispatch()
	let userLoading = useSelector(selectUserLoading)
	const user = useSelector(selectUser)

	const getDocument = useCallback(async () => {
		if (!userLoading && !fetched && (userIDCookie || user.id)) {
			setLoading(true)
			const token = currentUser ? await currentUser.getIdToken() : ''
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ id: props.docID }),
			}
			fetch(process['env']['REACT_APP_API_ROOT'] + '/document/retrieve/', requestOptions)
				.then((res) => res.json())
				.then((doc: completeDoc & { success: boolean }) => {
					setLoading(false)
					dispatch(setShowNav({ value: true }))
					if (doc) {
						setFetched(true)
						dispatch(createNewDocument({ docID: props.docID }))
						dispatch(setSetup({ docID: props.docID, setup: doc.setup }))
						dispatch(setContent({ docID: props.docID, content: doc.content }))
						dispatch(setTitle({ docID: props.docID, title: doc.title }))
						dispatch(setLastSavedTitle({ docID: props.docID, lastSavedTitle: doc.title }))
						dispatch(setLastSavedContent({ docID: props.docID, lastSavedContent: doc.content }))
					} else {
						setInvalid(true)
					}
				})
				.catch((e) => {
					Sentry.captureException(e)
				})
		}
	}, [currentUser, dispatch, props.docID, userLoading, fetched, userIDCookie, user.id])

	useEffect(() => {
		if (props.docID === '') {
			setInvalid(true)
			return
		}
		getDocument()
	}, [props.docID, userLoading, getDocument, fetched, user])

	useEffect(() => {
		dispatch(setLastAggregatePageVisited({ value: '/create' }))
		//Update only on initial page load
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const loader = (
		<div id={pageName}>
			<Loader />
		</div>
	)

	if (invalid) {
		return (
			<div id={pageName}>
				<h5 className="notFound header-md">Document not found</h5>
			</div>
		)
	}

	if (loading) return loader

	return <Essay {...props} />
}

export default Create
