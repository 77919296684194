import { useCallback, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { getCookie } from '../App'
import Loader from '../Components/Loader'
import { CONSTANTS } from '../constants'
import { useAuth } from '../contexts/Auth'
import {
	selectLastAggregatePageVisited,
	selectUser,
	selectUserPagesCache,
	setUserPagesCache,
} from '../redux/systemSlice'
import { options, templateType } from './Choose'
import './_New.scss'
import * as Sentry from '@sentry/react'
import { useCookieUser } from '../hooks/cookieUser'

function New(props: RouteComponentProps & { template: string | undefined }) {
	const { userIDCookie } = useCookieUser()
	const [cookies, setCookie] = useCookies(['docs'])
	const [loading, setLoading] = useState(false)
	const [created, setCreated] = useState(false)
	const { currentUser } = useAuth() as any
	const user = useSelector(selectUser)
	const lastPage = useSelector(selectLastAggregatePageVisited)
	const userPagesCache = useSelector(selectUserPagesCache)
	const pageName = 'new'

	const dispatch = useDispatch()

	const addDocToCookie = useCallback(
		(id: number) => {
			const existingDocs = getCookie('docs')
			if (existingDocs && existingDocs.length) {
				setCookie('docs', JSON.stringify([...JSON.parse(existingDocs), id]))
			} else {
				setCookie('docs', JSON.stringify([id]))
			}
		},
		[setCookie]
	)

	const createDoc = useCallback(
		async (templateID: string | undefined) => {
			if ((userIDCookie || user.id) && templateID && cookies && !created && !loading) {
				const template: templateType | undefined = options.find((option) => {
					return option.id === templateID
				})
				if (!template) return

				setLoading(true)
				const requestOptions = {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
					},
					body: JSON.stringify({ template: template.id, userId: user.id ?? userIDCookie }),
				}
				fetch(process['env']['REACT_APP_API_ROOT'] + '/document/create/', requestOptions)
					.then((res) => res.json())
					.then((result: { id: number } | undefined) => {
						if (!currentUser && result?.id) {
							addDocToCookie(result.id)
						}
						if (result?.id) {
							setCreated(true)
							setLoading(false)
							dispatch(setUserPagesCache({ ...userPagesCache, dirty: true }))
							window.location.replace(
								process.env.REACT_APP_APP_ROOT +
									CONSTANTS.CREATE_PATH +
									'/' +
									template.id +
									'/' +
									btoa(result.id.toString())
							)
						}
					})
					.catch((e) => {
						Sentry.captureException(e)
					})
			}
		},
		[userIDCookie, addDocToCookie, currentUser, user.id, dispatch, userPagesCache, cookies, created, loading]
	)

	useEffect(() => {
		if (lastPage === '/blog' || lastPage === '/create') {
			window.location.replace(process.env.REACT_APP_APP_ROOT + '/choose')
			return
		}

		createDoc(props.template ? props.template : undefined)
	}, [userIDCookie, cookies, createDoc, dispatch, props.template, created, loading, lastPage])

	const loader = (
		<div id={pageName}>
			<Loader />
		</div>
	)

	if (loading || !created) {
		return loader
	}

	return <></>
}

export default withRouter(New)
